<template>
  <div class="col pt-2">
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      text: 'Секундочку. Входим...'
    }
  },
  async created () {
    await this.login()
  },
  methods: {
    async login () {
      const token = this.$route.query.token

      this.$store.commit('auth/setAuthToken', token)
      this.$router.replace({ name: 'app' })
    }
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
</style>
